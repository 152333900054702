angular.module('app.controllers').controller('MainController', ['$scope', '$http', '$window', 'appConfig', '$cookies',
    function($scope, $http, $window, appConfig, $cookies) {
        //troca de perfil
        $scope.itemClicked = function($index, $key) {
            toastr.options = {
                "closeButton": false,
                "debug": false,
                "newestOnTop": false,
                "progressBar": true,
                "positionClass": "toast-top-center",
                "preventDuplicates": false,
                "onclick": null,
                "showDuration": "300",
                "hideDuration": "1000",
                "timeOut": "2000",
                "extendedTimeOut": "1000",
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            }
            var paramFiltro = [];
            paramFiltro['perfil'] = JSON.stringify($index);
            paramFiltro['idPerfil'] = $key;
            //ajax http
            $http({
                method: 'GET',
                url: appConfig.baseUrl + '/changeSessionHttp',
                params: paramFiltro
            }).then(function successCallback(response) {
                setTimeout(function() {
                    window.location.href = '/';
                }, 2000);
                toastr["success"]('Perfil alterado com sucesso. <br> <b>Aguarde carregando layout</b>.');
            }, function errorCallback(response) {
                return response;
            }).finally(function() {});
        };
        //remove cookie from search beneficio
        $scope.removeCookie = function () {
            $cookies.remove("searchFilter");
            $cookies.remove("searchFilterLista");
        };
    }
]);