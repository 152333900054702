/**
 * [Classe de configuração, componentização e diretivas para angularJS]
 * @type {angular.Module}
 * @author Autor
 * @version 1.0
 */
var app = angular.module('app', ['ngRoute', 'app.controllers', 'app.services', 'ui.grid', 'ui.grid.pagination', 'ui.grid.selection',
    'ui.grid.exporter', 'ui.grid.moveColumns', 'ui.grid.resizeColumns', 'ui.grid.autoResize', 'ui.bootstrap', 'angular-loading-bar']);
angular.module('app.controllers', ['ngMaterial', 'ngMessages', 'mgcrea.ngStrap', 'ngSanitize', 'ngAnimate', 'ngResource', 'ngCookies']);
angular.module('app.services', ['ngResource']);
angular.module('app')
    .config(function ($typeaheadProvider) {
        angular.extend($typeaheadProvider.defaults, {
            animation: 'am-flip-x',
            minLength: 2,
        });
    });
app.provider('appConfig', function () {
    var config = {
        baseUrl: appUrl
    };
    return {
        config: config,
        $get: function () {
            return config;
        }
    }
});
app.config(['$routeProvider', '$qProvider', '$httpProvider', '$modalProvider',
    function ($routeProvider, $qProvider, $httpProvider, $modalProvider) {
        $qProvider.errorOnUnhandledRejections(false);
        /*
         * GLOBAL HTTP HANDLING ERROR - SESSION EXPIRED LARAVEL
         * -----------------------
         */
        $httpProvider.interceptors.push(['$q', function ($q) {
            return {
                'responseError': function (rejection) {
                    if (rejection.status === 401 || rejection.status == 440) {
                        window.location.href ="/login";
                    }
                    return $q.reject(rejection);
                }
            };
        }]);
        $httpProvider.defaults.transformResponse = function (data, headers) {
            var headersGetter = headers();
            if (headersGetter['content-type'] == 'application/json' || headersGetter['content-type'] == 'text/json') {
                var dataJson = JSON.parse(data);
                if (dataJson.hasOwnProperty('data')) {
                    dataJson = dataJson.data;
                }
                return dataJson;
            }
            return data;
        };
        angular.extend($modalProvider.defaults, {
            html: true
        });
        $routeProvider
        //user
            .when('/seguranca/users', {
                controller: 'UserListController'
            }).when('/seguranca/users/:id/edit', {
            controller: 'UserEditController'
        })
        //perfil
            .when('/seguranca/perfis', {
                controller: 'PerfilListController'
            })
    }
]);
//directive LOADING for http requests
app.directive('loading', ['$http', function ($http) {
    return {
        restrict: 'A',
        link: function (scope, elm, attrs) {
            scope.isLoading = function () {
                return $http.pendingRequests.length > 0;
            };
            scope.$watch(scope.isLoading, function (v) {
                if (v) {
                    elm.show();
                } else {
                    elm.hide();
                }
            });
        }
    };
}]);
//FACTORIES
app.factory('grideService', grideService);
app.factory('modalService', modalService);
/**
 * @param i18nService
 * @param appConfig
 * @param uiGridExporterService
 * @param uiGridExporterConstants
 * @param uiGridConstants
 * @param $window
 * @param $http
 * @param modalService
 * @param $modal
 * @returns {{montaGrideOptions: montaGrideOptions}}
 */
function grideService(i18nService, appConfig, uiGridExporterService, uiGridExporterConstants, uiGridConstants, $window, $http, modalService, $modal) {
    //return
    var service = {
        montaGrideOptions: montaGrideOptions
    };
    return service;
    /**
     * [montaGrideOptions retorna componente ui grid angularjs]
     * @param $scope
     * @param $url
     * @param $columnDefs
     * @param $urlRouteQuery
     * @param $pageSize
     * @param $showActionCell
     * @param $addButtonAction
     * @param $widthActionColumn
     * @param $filterOptionsDefault
     * @param $iniciaGride
     * @returns {{paginationPageSizes: [*,*,*,*], paginationPageSize: (number|*), useExternalFiltering: boolean, useExternalPagination: boolean, useExternalSorting: boolean, enableGridMenu: boolean, enableFiltering: boolean, enableColumnResize: boolean, enableSelectAll: boolean, enableRowHashing: boolean, enableColumnResizing: boolean, onRegisterApi: onRegisterApi, exporterAllDataFn: exporterAllDataFn, exporterPdfDefaultStyle: {fontSize: number}, exporterPdfTableStyle: {margin: [number,number,number,number]}, exporterPdfTableHeaderStyle: {fontSize: number, bold: boolean, italics: boolean, color: string}, exporterPdfHeader: {text: string, style: string}, exporterPdfFooter: exporterPdfFooter, exporterPdfCustomFormatter: exporterPdfCustomFormatter, exporterPdfOrientation: string, exporterPdfPageSize: string, exporterPdfMaxGridWidth: number, exporterCsvFilename: string, exporterCsvLinkElement: Object}|*}
     */
    function montaGrideOptions($scope, $url, $columnDefs, $urlRouteQuery, $pageSize, $showActionCell, $addButtonAction, $widthActionColumn, $filterOptionsDefault, $iniciaGride) {
        //default parameters
        $pageSize =  (angular.isUndefined($pageSize) || $pageSize === null) ? 5 : $pageSize;
        $showActionCell =  (angular.isUndefined($showActionCell) || $showActionCell === null) ? true : $showActionCell;
        $addButtonAction =  (angular.isUndefined($addButtonAction) || $addButtonAction === null) ? null : $addButtonAction;
        $widthActionColumn =  (angular.isUndefined($widthActionColumn) || $widthActionColumn === null) ? 100 : $widthActionColumn;
        $filterOptionsDefault =  (angular.isUndefined($filterOptionsDefault) || $filterOptionsDefault === null) ? null : $filterOptionsDefault;
        $iniciaGride =  (angular.isUndefined($iniciaGride) || $iniciaGride === null) ? true : $iniciaGride;
        /**
         * toastr default options
         */
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-top-center",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "1000",
            "hideDuration": "1000",
            "timeOut": "5000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        //Filtro default
        if ($filterOptionsDefault !== null) {
            $scope.filterOptions = $filterOptionsDefault;
        }
        //language
        i18nService.setCurrentLang('pt-br');
        //variaveis pagination default
        var paginationOptions = {
            pageNumber: 1,
            sort: null,
            field: null,
        };
        //gride options
        $scope.gridOptions = {
            paginationPageSizes: [$pageSize, $pageSize * 50, $pageSize * 100, $pageSize * 200],
            paginationPageSize: $pageSize,
            useExternalFiltering: false,
            useExternalPagination: true,
            useExternalSorting: true,
            enableGridMenu: true,
            enableFiltering: false,
            enableColumnResize: true,
            enableSelectAll: true,
            enableRowHashing: false,
            enableColumnResizing: true,
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.handleWindowResize();
                $scope.gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
                    if (getPage) {
                        if (sortColumns.length > 0) {
                            paginationOptions.sort = sortColumns[0].sort.direction;
                            paginationOptions.field = sortColumns[0].colDef.field;
                        } else {
                            paginationOptions.sort = null;
                            paginationOptions.field = null;
                        }
                        getPage(grid.options.paginationCurrentPage, grid.options.paginationPageSize, paginationOptions.sort, paginationOptions.field, $scope.filterOptions);
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize, grid) {
                    if (getPage) {
                        getPage(newPage, pageSize, paginationOptions.sort, paginationOptions.field, $scope.filterOptions);
                    }
                });
            },
            exporterAllDataFn: function () {
                return getPage(1, $scope.gridOptions.totalItems, paginationOptions.sort, paginationOptions.field, $scope.filterOptions).then(function () {
                    $scope.gridOptions.useExternalPagination = false;
                    $scope.gridOptions.useExternalSorting = false;
                });
            },
            exporterPdfDefaultStyle: {
                fontSize: 9
            },
            exporterPdfTableStyle: {
                margin: [30, 30, 30, 30]
            },
            exporterPdfTableHeaderStyle: {
                fontSize: 10,
                bold: true,
                italics: true,
                color: 'red'
            },
            exporterPdfHeader: {
                text: "Report",
                style: 'headerStyle'
            },
            exporterPdfFooter: function (currentPage, pageCount) {
                return {
                    text: currentPage.toString() + ' de ' + pageCount.toString(),
                    style: 'footerStyle'
                };
            },
            exporterPdfCustomFormatter: function (docDefinition) {
                docDefinition.styles.headerStyle = {
                    fontSize: 22,
                    bold: true
                };
                docDefinition.styles.footerStyle = {
                    fontSize: 10,
                    bold: true
                };
                return docDefinition;
            },
            exporterPdfOrientation: 'portrait',
            exporterPdfPageSize: 'LETTER',
            exporterPdfMaxGridWidth: 500,
            exporterCsvFilename: 'file.csv',
            exporterCsvLinkElement: angular.element(document.querySelectorAll(".custom-csv-link-location"))
        };
        //default buttons/actions
        $scope.edit = function ($id) {
            $window.location.href = appConfig.baseUrl + $url + 'edit/' + $id;
        };
        $scope.view = function ($id) {
            $http({
                method: 'GET',
                //cache: true,
                url: appConfig.baseUrl + $url + $id
            }).then(function successCallback(response) {
                $scope.conteudo = response.data.html;
                $modal({
                    title: $scope.titleModal,
                    show: true,
                    html: true,
                    id: 'modalView',
                    scope: $scope,
                    templateUrl: '/../partials/modal-view.html'
                });
                $modal.show;
            }, function errorCallback(response) {
                toastr["error"]('Ocorreu um erro ao realizar a operação');
            });
        };
        $scope.delete = function ($id, $row) {
            var index = $scope.gridOptions.data.indexOf($row.entity);
            modalService.showDeleteConfirmation($scope.headerModalDelete, $scope.titleModalDeleteConfirmation).then(function () {
                $http({
                    method: 'DELETE',
                    cache: true,
                    url: appConfig.baseUrl + $url + $id
                }).then(function successCallback(response) {
                    if (response.data.error == false) {
                        $scope.gridOptions.data.splice(index, 1);
                        toastr["success"](response.data.msg);
                    } else {
                        toastr["error"](response.data.msg);
                    }
                }, function errorCallback(response) {
                    toastr["error"]('Ocorreu um erro ao realizar a operação');
                });
            });
        };
        //html action default + button adcional SE for passado
        var buttonActions = '<button tooltip-append-to-body="true" uib-tooltip="Alterar" tooltip-placement="bottom-left" ng-show="row.entity.CanEdit == true" class="btn btn-xs btn-warning" ng-click="grid.appScope.edit(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-edit"></span></button>  '
            + '<button tooltip-append-to-body="true" uib-tooltip="Visualizar" tooltip-placement="bottom-left" ng-show="row.entity.CanList == true" class="btn btn-xs btn-info" ng-click="grid.appScope.view(row.entity.id); $event.stopPropagation();"><span class="glyphicon glyphicon-eye-open"></span></button>  '
            + '<button tooltip-append-to-body="true" uib-tooltip="Excluir" tooltip-placement="bottom-left" ng-show="row.entity.CanDelete == true" class="btn btn-xs btn-danger" ng-click="grid.appScope.delete(row.entity.id, row); $event.stopPropagation();"><span class="glyphicon glyphicon-trash"></span></button>  '
            + (($addButtonAction !== null) ? $addButtonAction : '');
        //default var action config
        var columnDefsActionDefaut = {
            name: 'Ação',
            enableFiltering: false,
            enableSorting: false,
            width: $widthActionColumn,
            cellTemplate: buttonActions
        };
        //add default action button final do array, de acordo com parametro $showActionCell
        if ($showActionCell) {
            $columnDefs.push(columnDefsActionDefaut);
        }
        //clear filter search
        $scope.clearSearch = function () {
            $scope.isSearchClick = false;
            if (angular.isDefined($scope.filterOptions)) {
                if ($scope.filterOptions !== null) {
                    delete $scope.filterOptions.filtro;
                }
            }
            $scope.filterOptions = null;
            $scope.filterOptions = $filterOptionsDefault;
            $scope.gridOptions.useExternalPagination = true;
            $scope.gridOptions.useExternalSorting = true;
            $scope.filterGrid();
        }
        //external search filter
        $scope.filterGrid = function () {
            getPage($scope.gridOptions.paginationCurrentPage, $scope.gridOptions.paginationPageSize, paginationOptions.sort, paginationOptions.field, $scope.filterOptions);
        };
        //Get page server side
        var getPage = function (curPage, pageSize, sort, field, arrFilter) {
            $scope.msgNoData = false;
            var sort = (angular.isUndefined(sort) || sort === null) ? null : sort;
            var field = (angular.isUndefined(field) || field === null) ? null : field;
            var arrFilter = (angular.isUndefined(arrFilter) || arrFilter === null) ? null : arrFilter;
            var _scope = $scope;
            var paramFiltro = [];
            if (sort !== null && field !== null) {
                paramFiltro['order'] = field + ',' + sort;
            }
            if (arrFilter !== null) {
                paramFiltro['filtro'] = JSON.stringify(arrFilter);
            }

            //ajax http
            return $http({
                method: 'GET',
               // cache: true,
                url: appConfig.baseUrl + $urlRouteQuery,
                params: paramFiltro
            }).then(function successCallback(response) {
                var firstRow = (curPage - 1) * pageSize;
                $scope.gridOptions.totalItems = count(response.data);
                $scope.gridOptions.data = response.data.slice(firstRow, firstRow + pageSize);
                $scope.msgNoData = (count(response.data) == 0) ? true : false;
            }, function errorCallback(response) {
                return response;
            }).finally(function () {
            });
            $scope.$apply();
        };
        //inicia gride
        if ($iniciaGride) {
            getPage(1, $scope.gridOptions.paginationPageSize, paginationOptions.sort, paginationOptions.field, $scope.filterOptions);
        }
        $scope.gridOptions.columnDefs = $columnDefs;
        return $scope.gridOptions;
    }
}
/**
 * [modalService description]
 * @param  {[type]} $rootScope
 * @param  {[type]} $q
 * @param  {[type]} $modal
 * @return {[type]}
 */
function modalService($rootScope, $q, $modal) {
    var defaultOptions = getModalDefaultOptions();
    //return;
    var service = {
        showDeleteConfirmation: showDeleteConfirmation
    };
    return service;
    /**
     * [getModalDefaultOptions
     * @return {[type]}
     */
    function getModalDefaultOptions() {
        return {
            templateUrl: '/../partials/modal.html',
            closeButtonText: 'Fechar',
            actionButtonText: 'OK',
            headerText: 'Continuar?',
            bodyText: 'Executar essa ação?',
            showActionButton: true,
            showCloseButton: true,
            headerClass: ''
        };
    }

    /**
     * [showDeleteConfirmation]
     * @param  {[type]} title
     * @param  {[type]} message
     * @return {[type]}
     */
    function showDeleteConfirmation(title, message) {
        return showModal({
            closeButtonText: 'Cancelar',
            actionButtonText: 'Excluir',
            headerText: title,
            bodyText: message,
            headerClass: 'dialog-header-confirm'
        });
    }

    /**
     * [showModal]
     * @param  {[type]} customModalOptions
     * @return {[type]}
     */
    function showModal(customModalOptions) {
        var deferred = $q.defer();
        //Create temp objects to work with since we're in a singleton service
        var tempModalOptions = {};
        //Map modal.html $scope custom properties to defaults defined in service
        angular.extend(tempModalOptions, defaultOptions, customModalOptions);
        var scope = $rootScope.$new();
        scope.modalOptions = tempModalOptions;
        scope.modalOptions.ok = function (result) {
            deferred.resolve();
            modal.hide();
        };
        scope.modalOptions.close = function (result) {
            deferred.reject();
            modal.hide();
        };
        var modal = $modal({
            scope: scope,
            templateUrl: scope.modalOptions.templateUrl,
            title: scope.modalOptions.headerText,
            content: scope.modalOptions.bodyText,
            show: true
        });
        return deferred.promise;
    }
}